<template>
  <b-form-checkbox-group v-model="selectedList">
    <b-row class="no-gutters">
      <b-card
        v-for="(optionItem, index) in options"
        :key="index"
        class="p-2 mr-1 mb-1"
        :border-variant="isItemSelected(optionItem) ? 'primary' : ''"
        no-body
      >
        <b-form-checkbox :value="optionItem">
          {{ getTranslatedItem(optionItem) }}
        </b-form-checkbox>
      </b-card>
    </b-row>
  </b-form-checkbox-group>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    },
    translationPath: {
      type: String,
      required: false,
      default: "-"
    }
  },
  methods: {
    isItemSelected(item) {
      return this.selectedList.find(selectedItem => selectedItem === item);
    },
    getTranslatedItem(item) {
      if (this.translationPath === "") return this.$t(item);
      else if (this.translationPath === "-") return item;
      else return this.$t(`${this.translationPath}.${item}`);
    }
  },
  computed: {
    selectedList: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("change", val);
      }
    }
  }
};
</script>

<style scoped></style>
